import * as React from "react";
import { graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/pro-light-svg-icons";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/Layout";
import EventiGrid from "../../../components/EventiGrid/EventiGrid";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import {
  isEventoGratuito,
  parseQuotaPartecipazione,
} from "../../../utils/eventi";

export default function EventiResDisciplinaPage({ data }) {
  const {
    disciplineJson: disciplina,
    allEventiRes: { nodes: eventiRes },
    totaleEventi: { totalCount },
  } = data;

  const [crediti, setCrediti] = useState("");
  const [costo, setCosto] = useState("");
  const [pagina, setPagina] = useState(1);
  const eventiFiltered = eventiRes
    .filter((evento) => {
      const [min, max] = crediti.split("-").map((value) => parseInt(value, 10));

      return (
        crediti === "" ||
        (min <= parseInt(evento.crediti, 10) &&
          (!max || parseInt(evento.crediti, 10) < max))
      );
    })
    .filter((evento) => {
      const [min, max] = costo.split("-").map((value) => parseInt(value, 10));
      const quotaPartecipazione = parseQuotaPartecipazione(
        evento.quota_partecipazione
      );

      return (
        costo === "" ||
        (costo === "gratuiti" &&
          isEventoGratuito(evento.quota_partecipazione)) ||
        (min <= quotaPartecipazione &&
          (isNaN(max) || quotaPartecipazione < max))
      );
    });
  const eventiCurrentPage = eventiFiltered.slice(0, 20 * pagina);

  return (
    <Layout
      pageTitle={`Corsi ECM RES per ${disciplina.disciplina}`}
      description={`Scegli tra i ${totalCount} corsi ECM RES per ${disciplina.disciplina} disponibili.`}
    >
      <section className="position-relative">
        <StaticImage
          src="../../../images/eventi-res.jpg"
          className="img-header-home"
          alt={`Disciplina ${disciplina.disciplina}`}
          title={`Disciplina ${disciplina.disciplina}`}
        />
        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1 className="text-center text-white display-4 font-weight-bold">
              CORSI ECM RES per {disciplina.disciplina}
            </h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Scegli tra i {totalCount} corsi ECM RES per{" "}
              {disciplina.disciplina} disponibili.
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri i corsi
            </a>
          </div>
        </div>
      </section>
      <div id="sezione-corsi" className="container-fluid">
        <div className="row px-5 py-3">
          <div className="col-12">
            <h2 className="my-4 text-center titolo-sezione">
              Corsi ECM RES per {disciplina.disciplina} in evidenza
            </h2>
          </div>
          <FormRicerca
            handleChangeCrediti={(e) => {
              setCrediti(e.currentTarget.value);
              setPagina(1);
            }}
            handleChangeCosto={(e) => {
              setCosto(e.currentTarget.value);
              setPagina(1);
            }}
          />
        </div>
      </div>
      <EventiGrid eventi={eventiCurrentPage} tipologia="RES" />
      {eventiCurrentPage.length > 0 &&
        eventiCurrentPage.length < eventiFiltered.length && (
          <div className="container-fluid">
            <div className="row px-5 pt-3 pb-5">
              <div className="col-12 text-center">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setPagina((prev) => prev + 1)}
                >
                  Carica altri corsi
                </button>
              </div>
            </div>
          </div>
        )}
    </Layout>
  );
}

function FormRicerca({ handleChangeCrediti, handleChangeCosto }) {
  return (
    <>
      <div className="col-12 col-md-4 col-lg-3">
        <Form.Group className="mb-4">
          <Form.Label htmlFor="select-crediti">Crediti</Form.Label>
          <Form.Select id="select-crediti" onChange={handleChangeCrediti}>
            <option value="">Seleziona una fascia di crediti</option>
            <option value="0-10">Da 0 a 10 crediti</option>
            <option value="10-20">Da 10 a 20 crediti</option>
            <option value="20-30">Da 20 a 30 crediti</option>
            <option value="30-40">Da 30 a 40 crediti</option>
            <option value="40-">Oltre 40 crediti</option>
          </Form.Select>
        </Form.Group>
      </div>
      <div className="col-12 col-md-4 col-lg-3">
        <Form.Group className="mb-4">
          <Form.Label htmlFor="select-costo">Costo</Form.Label>
          <Form.Select id="select-costo" onChange={handleChangeCosto}>
            <option value="">Seleziona una fascia di prezzo</option>
            <option value="gratuiti">Gratuiti</option>
            <option value="0-50">Da 0 a 50 euro</option>
            <option value="50-150">Da 50 a 150 euro</option>
            <option value="150-300">Da 150 a 300 euro</option>
            <option value="300-500">Da 300 a 500 euro</option>
            <option value="500-">Oltre 500 euro</option>
          </Form.Select>
        </Form.Group>
      </div>
    </>
  );
}

export const query = graphql`
  query ($disciplina: String = "") {
    disciplineJson(disciplina: { eq: $disciplina }) {
      id
      disciplina
    }
    totaleEventi: allEventiJson(
      filter: {
        professioni: { elemMatch: { disciplina: { eq: $disciplina } } }
        tipo_evento: { eq: "RES" }
      }
    ) {
      totalCount
    }
    allEventiRes: allEventiJson(
      sort: { fields: data_fine_timestamp, order: DESC }
      filter: {
        tipo_evento: { eq: "RES" }
        professioni: { elemMatch: { disciplina: { eq: $disciplina } } }
      }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        quota_partecipazione
        professioni {
          disciplina
          professione
        }
      }
    }
  }
`;
